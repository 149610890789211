import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';
import { IPrivacyPolicyProperties } from '../../global/wordpress-page/privacy-policy/privacy-policy-interface';
import ContainerBox from '../../components/container-box/container-box';

export default class PrivacyPolicy extends BaseComponent<IPrivacyPolicyProperties> {
  public render(): ReactNode {
    const { [0]: privacyPolicyPage } = this.props.data.privacyPolicyPage.nodes;
    return (
      <WordPressPageLayout
        title={privacyPolicyPage.title}
      >
        <ContainerBox className='c-section--pageheader'>
          <div className="c-pageheader">
            <BreadCrumb crumbItems={[
              {
                label: 'State of Democracy',
                location: '/',
              },
              {
                label: privacyPolicyPage.title,
                location: 'privacy-policy',
              },
            ]} />
            <div className="c-section">
              <h1 className="c-section__title">{privacyPolicyPage.title}</h1>
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className='c-section--content'>
          <div className='s-content'
            dangerouslySetInnerHTML={{ __html: privacyPolicyPage.content }}
          ></div>
        </ContainerBox>
      </WordPressPageLayout>
    );
  }
}
export const query = graphql`
  query PrivacyPolicyPage {
    privacyPolicyPage: allWpPage(filter: { slug: { eq: "privacy-policy" } }) {
      nodes {
        id
        title
        uri
        slug
        content
        status
      }
    }
  }
`;
